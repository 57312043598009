import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/header/Header'
import Form from './components/form/Form'
import Home from './components/home/Home'
import PrintingSubpage from './components/printingSubpage/printingSubpage.jsx'
import FAQ from './components/faq/FAQ'
import ProjectsSubpage from './components/projectsSubpage/projectsSubpage.jsx';
import "./components/header/header.css";
import Testimonials from './components/home/partials/Testimonials';
import Map from './components/map/Map';
import Contact from './components/contact/Contact'
import Footer from "./components/footer/Footer";
import DesigningSubpage from './components/designingSubpage/designingSubpage';


const Layout = ({ children }) => (
    <>
        <Header />
        {children}
        <Form />
        <Map />
        <Footer />
    </>
);

const ContactLayout = ({ children }) => (
    <>
        <Header />
        {children}
        <Map />
        <Footer />
    </>
);

const App = () => {
    return (
        <Router>
            <>
                <Switch>
                    <Route exact path="/" render={() => <Layout><Home /></Layout>} />
                    <Route exact path="/druk3d" render={() => <Layout><PrintingSubpage /></Layout>} />
                    <Route exact path="/faq" render={() => <Layout><FAQ /></Layout>} />
                    <Route exact path="/projekty" render={() => <Layout><ProjectsSubpage /></Layout>} />
                    <Route exact path="/kontakt" render={() => <ContactLayout><Contact /></ContactLayout>} />
                    <Route exact path="/projektowanie3d" render={() => <Layout><DesigningSubpage /></Layout>} />
                </Switch>
            </>
        </Router>
    );
};

export default App;
