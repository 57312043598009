import React from 'react'
import './footer.css';

const Footer = () => {
    return (
        <div className='footer-container'>
            <div className='info-container'>
                <h1>+48 693 827 505</h1>
                <h2>matrix3d.kontakt@gmail.com</h2>
                <h2>Warszawa, Nowolipki 22</h2>
            </div>
            <div className='image'>
                <img src="/images/icons/footer.webp" alt='footer' />
            </div>
        </div>
    );
};

export default Footer;