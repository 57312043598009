import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './form.css';

const Form = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [confirmation, setConfirmation] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: '.stl, .png, .jpg, .jpeg, .pdf' });

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('description', description);
    files.forEach((file) => {
      formData.append('files', file);
    });

    fetch('http://localhost:5000/send', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.text())
      .then((message) => {
        console.log(message);
        setConfirmation("Formularz został wysłany!");
      })
      .catch(error => {
        console.error('Błąd wysyłania formularza:', error);
        setConfirmation("Nie udało się wysłać formularza. Spróbuj ponownie.");
      });
  };

  return (
    <section id="cta">
      <div className="parent-cta-container">
        <div className='cta-container'>
          <h1>Skontaktuj się z nami, a my zajmiejmy się resztą, wycena w kilka godzin</h1>
          <h2>matrix3d.kontakt@gmail.com</h2>
        </div>
        {/*(<form className="form-container" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name"></label>
            <input
              type="text"
              id="name"
              value={name}
              placeholder="Twoje imię"
              onChange={(event) => setName(event.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email"></label>
            <input
              type="email"
              id="email"
              value={email}
              placeholder="Twój adres e-mail"
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone"></label>
            <input
              type="tel"
              id="phone"
              value={phone}
              placeholder="Twój numer telefonu (opcjonalnie)"
              onChange={(event) => setPhone(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="description"></label>
            <textarea
              id="description"
              value={description}
              placeholder="Dodatkowe informacje"
              onChange={(event) => setDescription(event.target.value)}
              required
            />
          </div>
          <div {...getRootProps()} className="form-group dropzone">
            <input {...getInputProps()} />
            {isDragActive ?
              <p>Upuść pliki tutaj...</p> :
              <p>Przeciągnij i upuść pliki tutaj, lub kliknij, aby wybrać pliki</p>
            }
          </div>
          <div className="form-group">
            <button type="submit">Wyślij</button>
          </div>
          </form>*/}
        {confirmation && <p className="confirmation-message">{confirmation}</p>}
      </div>
    </section>
  );
};

export default Form;
