import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Element } from 'react-scroll';

const SetScrollBehavior = () => {
  const map = useMap();
  useEffect(() => {
    map.scrollWheelZoom.disable();
    const enableWithCtrl = (e) => {
      if (e.ctrlKey) {
        map.scrollWheelZoom.enable();
      } else {
        map.scrollWheelZoom.disable();
      }
    };

    document.addEventListener('keydown', enableWithCtrl);
    document.addEventListener('keyup', enableWithCtrl);

    return () => {
      document.removeEventListener('keydown', enableWithCtrl);
      document.removeEventListener('keyup', enableWithCtrl);
    };
  }, [map]);

  return null;
};

const Map = () => {
  const position = [52.2437936017744, 20.987579652245554];

  return (
    <Element name="map">
      <MapContainer center={position} zoom={13} style={{ height: "50vh", width: "100%", zIndex: "0" }}>
        <SetScrollBehavior />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>
            Matrix 3D<br />
            Nowolipki 22, Warszawa
          </Popup>
        </Marker>
      </MapContainer>
    </Element>
  )
}

export default Map;
