import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './contact.css';

const Contact = () => {
  return (
    <div className='contact-page'>
      <h1>Skontaktuj się z nami!</h1>
      <div className="contact-info">
        <ScrollLink to="map" smooth={true} duration={500}>
          <div className="info-item" style={{ cursor: "pointer" }}>
            <img src="/images/icons/address.webp" alt='adres' width="120px" />
            <h2>Adres</h2>
            <span style={{textAlign: 'center'}}>Biuro projektowe Warszawa: <br/> Nowolipki 22, 01-019 Warszawa</span>
            <span style={{textAlign: 'center'}}>Biuro projektowe Gdańsk: <br/> Kombatantów 4, 80-462 Gdańsk</span>
            <span style={{textAlign: 'center'}}>Drukarnia: <br/> Szpitalna 73b, 16-400 Suwałki</span>
          </div>
        </ScrollLink>
        <div className="info-item">
          <img src="/images/icons/email.webp" alt='email' width="120px" />
          <h2>Email</h2>
          <span>matrix3d.kontakt@gmail.com</span>
        </div>
        <div className="info-item">
          <img src="/images/icons/phone.webp" alt='telefon' width="120px" />
          <h2>Telefon</h2>
          <span>+48 693 827 505</span>
        </div>
      </div>
    </div>
  );
}

export default Contact;

