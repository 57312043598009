import React, { useState } from "react";

const photos = [
  "./images/home/gallery/dobor-materialu.webp",
  "./images/home/gallery/womenModel.webp",
  "./images/airplaneRudder.webp",
  "./images/home/gallery/przekazanie-klientowi-wydruku.webp",
  "./images/home/gallery/prusa-drukarki3d.webp",
];

const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(null);

  const openLightbox = (src) => {
    setCurrentImage(src);
  };

  const closeLightbox = () => {
    setCurrentImage(null);
  };

  return (
    <div className="gallery-container">
      <h1>Galeria druków</h1>
      <ul className="main-nav">
        {photos.map((src, index) => (
          <li key={src} className={`item${index + 1}`}>
            <div className="bg">
              <img src={src} alt="Wydruki 3D - matrix3d.pl" onClick={() => openLightbox(src)} />
            </div>
          </li>
        ))}
      </ul>
      {currentImage && (
        <div className="lightbox" onClick={closeLightbox}>
          <img src={currentImage} alt="Wydruk w plastiku" />
        </div>
      )}
    </div>
  );
};

export default Gallery;
