import React from 'react';
import { Link as ScrollLink } from 'react-scroll';

const Steps = () => {
    return (
        <section id="how">
            <div className='how-container'>
                <div className='step'>
                    <img src="/images/icons/folder.webp" alt='folder' width="100px" style={{ width: '100px', maxWidth: '100%' }} />
                    <h2>Wyślij do nas plik projektu</h2>
                    <p>Pliki jak .stl .mf3 .obj</p>
                    <p>Dostarczymy ci wycenę w 24h</p>
                </div>
                <div className='step'>
                    <img src="/images/icons/print.webp" alt='print' width="100px" style={{ width: '100px', maxWidth: '100%' }} />
                    <h2>Wydruk</h2>
                    <p>Druk gotowy do 2 dni</p>
                </div>
                <div className='step'>
                    <img src="/images/icons/delivery.webp" alt='delivery' width="100px" style={{ width: '100px', maxWidth: '100%' }} />
                    <h2>Wysyłka/Odbiór</h2>
                    <p>Inpost lub Kurier</p>
                </div>
            </div>
            <ScrollLink to="cta" smooth={true} duration={500}>
                <div className='button-container'>
                    <h3><a href="#cta" className="button">Zamawiam</a></h3>
                </div>
            </ScrollLink>
        </section>
    )
};

export default Steps;
