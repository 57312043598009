import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import './projectsSubpage.css';

const projectImages = [
  "images/projectsSubpage/gallery/3pack_fanatec_QR2_wall_mounts.webp", 
  "images/projectsSubpage/gallery/3pack_fanatec_wall_mounts.webp",
  "images/projectsSubpage/gallery/advance_paddle_mounts.webp",
  "images/projectsSubpage/gallery/fanatec_dashboard_raspberry.webp",
  "images/projectsSubpage/gallery/fanatec_dashboard_rubber.webp",
  "images/projectsSubpage/gallery/formula1_stand.webp",
  "images/projectsSubpage/gallery/wind_sim.webp",
  "images/projectsSubpage/gallery/power_supply_mounts.webp",
  "images/projectsSubpage/gallery/galaxy_black_stand_close_up.webp",
  "images/projectsSubpage/gallery/galaxy_black_stand_close_up_down.webp",
  //"images/projectsSubpage/gallery/micro_prints_mercedes.webp",
  "images/projectsSubpage/gallery/trustmaster_mount_spring.webp",
  "images/projectsSubpage/gallery/trustmaster_mount.webp",
  "images/projectsSubpage/gallery/wheel_stand_close_up.webp",
  "images/projectsSubpage/gallery/wheel_stand_two.webp",
];

const ProjectsSubpage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <div className="projects-gallery-container">
        <div className="projects-gallery">
          {projectImages.map((src, index) => (
            <img
              key={index}
              src={src}
              className="gallery__img"
              onClick={() => openLightbox(index)}
            />
          ))}
        </div>
      </div>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={projectImages.map((src, index) => ({
                src,
                caption: `Projekt ${index + 1}`
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <div className="case-study">
        <div className="images">
          <div className="small-images">
            <div className="small-image">
              <img src="./images/projectsSubpage/artykul1-1.webp" />
            </div>
            <div className="small-image">
              <img src="./images/projectsSubpage/artykul1-2.webp" />
            </div>
          </div>
          <div className="small-image" style={{ width: "100%", height: "" }}>
            <img src="./images/projectsSubpage/artykul1-3.webp" />
          </div>
        </div>
        <div className="text-section-1">
          <div className="tag-container">
            <span className="tag">SLA</span>
            <span className="tag">Żywica</span>
            <span className="tag">Skala micro</span>
            <span className="tag">Case Study</span>
          </div>

          <div className="main-text">
            <h2>Wstęp:</h2>
            Przeprowadziliśmy zamówienie klienta na druk 3D replik akcesoriów do modeli aut w masowej ilości, wykorzystując technologię SLA druku, czyli żywicy. Głównym celem tego projektu było dostarczenie klientowi wysokiej jakości produktów, które będą dokładnymi i realistycznymi replikami akcesoriów do modeli samochodów.
            <br />
            <h2>Jak:</h2>
            Aby osiągnąć sukces w tym zamówieniu, przeprowadziliśmy dokładne badania i analizy dotyczące wymagań klienta oraz naszych możliwości technologicznych. Wybraliśmy technologię SLA druku, ponieważ zapewnia ona wysoką precyzję i jakość druku, co jest kluczowe przy produkcji detali.
            <br />
            <br />
            Podczas procesu drukowania, użyliśmy wysokiej jakości żywicy, która pozwoliła nam uzyskać szczegółowe i wytrzymałe repliki akcesoriów. Nasza wydajna flota drukarek 3D pozwoliła na równoczesne drukowanie wielu elementów, co znacznie przyspieszyło czas realizacji zamówienia.
            <br />
            <h2>Wynik:</h2>
            Wynik naszych wysiłków był absolutnie zachwycający! Otrzymane repliki akcesoriów były perfekcyjne pod względem jakości, dokładności i detali. Spełniły oczekiwania klienta i pozwoliły mu na skuteczne wzbogacenie swoich modeli samochodów.
            <br />
            <br />
            Dzięki wykorzystaniu technologii SLA druku żywicznego, byliśmy w stanie dostarczyć zamówione repliki w bardzo krótkim czasie, co dodatkowo zwiększyło satysfakcję klienta z naszych usług.
            <br />
            <br />
            Cały proces przeprowadzenia zamówienia był nie tylko udany, ale także potwierdził nasze zaawansowane zdolności technologiczne i profesjonalne podejście do wykonywania zleceń, co umocniło naszą pozycję na rynku usług druku 3D.
            <br />
            <br />
            Klient został z nami na dalszy okres współpracy, z czego jesteśmy niezwykle dumni.
          </div>
        </div>
      </div>
      <div className="case-study">
        <div className="text-section-2">
          <div className="tag-container">
            <span className="tag">FDM</span>
            <span className="tag">SimRacing</span>
            <span className="tag">Uchwyt na telefon</span>
          </div>

          <div className="main-text">
            <h2>Wstęp:</h2>
            Założenie było proste, zrobić dopasowany produkt pod każdego klineta.
            Stworzyliśmy za tem mocowanie na telefon dla fanatyków SimRacingu. Jak wiemy każdy ma inny telefon, co za tym idzie inne wymiary tego telefonu. 
            <br />
            <br />
            Nasz projekt jest idealnie zaprojektowany żeby można było go modyfikować w kilka minut pod indywidualne wymagania klineta.
            <h2>Jak:</h2>
            Aby osiągnąć sukces w jednym z wielu bestsellerów , przeprowadziliśmy dokładną analize dotyczące wymagań. Wybraliśmy technologię FDM druku, ponieważ zapewnia ona potrzebną nam wytrzymałość na drgania i przeciążenia, jednocześnie nie odstawając jakościowo.
            <br />
            <br />
            Projekt CAD jest zaprojektowany żeby każdy z minimalną znajomością programu potrafił modyfikować mocowanie i w przeciągu kilku minut wysłać go do druku!
            <br />
            <br />
            Podczas procesu drukowania, używamy popularnego plastiku PLA, ponieważ model jest tak zaprojektowany że nie potrzebuję wysokiej klasy materiału żeby był wytrzymały, oszczędność pieniędzy i czasu!. 
            <br />
            <br />
            Nasza flota drukarek takich jak Bambu Lab X1C pozwala na wydruk takiego modelu w mniej niż godzinę co oznacza że zamówienia są gotowe do wysyłki po około 70 minutach od zamówienia, uwzględniajać proces modyfikacji CAD
            <h2>Wynik:</h2>
            Po raz kolejny, wielki sukces! Mocowanie sprzedajemy dla wielu klientów każdego miesiąca i każdy z nich jest zachwycony, średnia ocena to 9.8/10 !
            <br />
            <br />
            Dzięki wykorzystaniu technologii FDM, jesteśmy w stanie dostaczać zamówienia z ekspresowym czasem realizacji.
            <br />
            <br />
            Potwierdziliśmy ponownie nasze możliwości, od tamtej pory nieustannie rozwijamy kolejne produkty i sprzedajemy je na cały świat. Glówni klienci pochodzą z USA, Kanada, Australia i Niemcy.
          </div>
        </div>
        <div className="images">
          <div className="big-image">
            <img src="./images/projectsSubpage/artykul2-1.webp" alt="" />
          </div>
          <div className="big-image">
            <img src="./images/projectsSubpage/artykul2-2.webp" alt="" />
          </div>
        </div>

      </div>
    </div>
  )
}

export default ProjectsSubpage;