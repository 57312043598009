import React from 'react';
import CarouselSlider from './partials/CarouselSlider';
import Steps from './partials/Steps';
import Gallery from './partials/Gallery';
import Testimonials from './partials/Testimonials';
import Uses from './partials/Uses';
import './home.css';
import Banner from './partials/Banner';

const Home = () => {
  return (
    <>
      <Banner />
      <CarouselSlider />
      <Steps />
      <Uses />
      <Gallery />
      <Testimonials />
    </>
  )
}

export default Home