import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import './carouselSlider.css';

const CarouselSlider = () => {
  const slider = (
    <AwesomeSlider className='aws-btn'>
      <div data-src="images/banner_bambuP1P.webp"></div>
      <div data-src="images/home/proteza3d-reka-lekarz.webp"></div>
      <div data-src="images/home/dental.webp"></div>
      <div data-src="images/home/vase-ender.webp"></div>
    </AwesomeSlider>
  );

  return slider;
};

export default CarouselSlider;
