import React from 'react';
import './banner.css';

const Banner = () => {
    return (
        <div className='mobile-banner'>
            <img src="images/banner_bambuP1P.webp" alt="Banner Image" />
        </div>
    );
};

export default Banner;