import React, { useEffect } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import Headroom from 'react-headroom';
import "./header.css";

const Header = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const tabs = [
    { name: 'Home', path: '/' },
    { name: 'Druk 3D', path: '/druk3d' },
    { name: 'Projektowanie 3D', path: '/projektowanie3d' },
    { name: 'Ostatnie projekty', path: '/projekty' },
    { name: 'FAQ', path: '/faq' },
    { name: 'Kontakt', path: '/kontakt' },
  ];

  return (
    <section className="header">
      <Headroom>

        <h1 className="header-title">Matrix 3D</h1>
        <ul className="menu">
          {tabs.map(tab => (
            <li key={tab.name} className={location.pathname === tab.path ? 'current' : ''}>
              <NavLink
                to={tab.path}
                data-hover={tab.name}
                exact
              >
                {tab.name}
              </NavLink>
            </li>
          ))}
        </ul>

      </Headroom>
    </section>
  );
};

export default Header;


