import React, { useState } from "react";
import "./testimonials.css";

const testimonials = [
  { name: "Jakub", feedback: "Pewniak na rynku druku 3D w Polsce. Niezawodność i jakość." },
  { name: "Andrzej", feedback: "Nie mogłem być bardziej zadowolony, spełnia wszystkie oczekiwania." },
  { name: "Mateusz", feedback: "Bezkonkurencyjne ceny, studenckie :)" },
  { name: "Michał", feedback: "Pełen profesjonalizm od początku do końca. Wysoce polecam usługi Matrix3D!" },
];

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const handlePrev = () => {
    setCurrentTestimonial((old) => (old - 1 + testimonials.length) % testimonials.length);
  };

  const handleNext = () => {
    setCurrentTestimonial((old) => (old + 1) % testimonials.length);
  };

  const getTestimonials = () => {
    const prev = (currentTestimonial - 1 + testimonials.length) % testimonials.length;
    const next = (currentTestimonial + 1) % testimonials.length;
    return [testimonials[prev], testimonials[currentTestimonial], testimonials[next]];
  };


  return (
    <div className="testimonial-section">
      <h1>Co mówią o nas klienci</h1>
      <div className="testimonial-bg">
        <div className="testimonial-container">
          <button className="testimonial-button" onClick={handlePrev}>&lt;</button>
          <div className="testimonial-content">
            {getTestimonials().map((testimonial, index) => (
              <div key={testimonial.name} className={`testimonial-item ${index === 1 ? 'highlight' : ''}`}>
                <div className="testimonial-box">
                  <h3>{testimonial.name}</h3>
                  <p>{testimonial.feedback}</p>
                </div>
              </div>
            ))}
          </div>
          <button className="testimonial-button" onClick={handleNext}>&gt;</button>
        </div>
      </div>
    </div>
  );
};


export default Testimonials;
