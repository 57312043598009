import React, { useState } from "react";
import './faq.css';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    }

    const questions = [
        {
            question: "Jak przebiega wycena druku?",
            answer: "Jednym słowem to szybko, dostajemy od Państwa projekt a resztą zajmujemy się my i dostarczamy wycenę w 24h od poniedziałku do niedzieli!"
        },
        {
            question: "Co jak nie mam projektu 3D?",
            answer: "Nic nie szkodzi, zdejmiemy z Państwa ten ciężar. Wystarczy przesłać nam wstępne wymagania projektu, my dopytamy się o szczegóły, zaprojektujemy i gotowe!"
        },
        {
            question: "Jak i gdzie można odebrać zamówienia?",
            answer: "Są 2 sposoby, stacjonarnie przy Szpitalna 73b w Suwałkach, lub możemy wysłać zamówienie (Paczkomat/kurier/poczta polska)"
        },
        {
            question: "Jak wygląda płatność?",
            answer: "Dostosujemy się do Państwa, oferujemy płatności przelewem, blik i gotówką"
        },
        {
            question: "Jakie mają Państwo doświadczenie?",
            answer: "W przemyślę siedzimy już od 5 lat, może wydawać się to mało ale w tym czasie zrelizowaliśmy już ponad 10.000 tysięcy Druków i kilkaset projektów!"
        },
    ]

    return (
        <div className="faq-container">
            <h1 className="faq-title">FAQ</h1>
            <h3 className="faq-subtitle">Najczęściej zadawane pytania</h3>
            <div className="faq-questions">
                {questions.map((item, index) => (
                    <div className={`faq-item ${activeIndex === index ? 'active' : ''}`} key={index} onClick={() => handleClick(index)}>
                        <div className="faq-question">
                            <p>{item.question}</p>
                        </div>
                        <div className="faq-answer">{item.answer}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
