import React from "react";
import './uses.css';

const Uses = () => {
    return (
        <div className="uses-container">
            <div className="uses-title">
                <h1>Stosujemy druk 3D w</h1>
            </div>
            <div className="icon-flex-container">
                <div className="icon-item">
                    <img src="/images/icons/uses/automotive.webp" alt="motoryzacja" />
                    <h3>Motoryzacji</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/electronics.webp" alt="przemysł elektroniczny" />
                    <h3>Przemyśle elektronicznym</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/medicine.webp" alt="medycyna" />
                    <h3>Medycynie</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/steering.webp" alt="simracing" />
                    <h3>SimRacingu</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/military.webp" alt="sprzęt wojskowy" />
                    <h3>Sprzęcie wojskowym</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/sparepart.webp" alt="zapasowe części przemysłowe" />
                    <h3>Zapasowych częściach przemysłowych</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/foundry.webp" alt="odlewnictwo" />
                    <h3>Odlewnictwie</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/furniture.webp" alt="branża meblarska" />
                    <h3>Branży meblarskiej</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/automation.webp" alt="automatyka przemysłowa" />
                    <h3>Automatyce przemysłowej</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/construction.webp" alt="budownictwo" />
                    <h3>Budownictwie</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/education.webp" alt="edukacja" />
                    <h3>Edukacji</h3>
                </div>
                <div className="icon-item">
                    <img src="/images/icons/uses/individual.webp" alt="zlecenia indywidualne" />
                    <h3>Zleceniach indywidualnych</h3>
                </div>
            </div>
        </div>
    )
}

export default Uses;