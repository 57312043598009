import React from "react";
import "./designingSubpage.css";

const DesigningSubpage = () => {
  return (
    <div>
      <div className="banner">
        <img src="images\designingSubpage\projektowanie3d-symulacja.webp" alt="background" />
      </div>
      <div className="case">
        <div className="list">
          <h2>Projektujemy pod: </h2>
          <h3>- Druk 3D FDM</h3>
          <p>
            Wykorzystujemy najnowocześniejsze technologie druku 3D Fused Deposition Modeling (FDM), które pozwalają nam
            przekształcać koncepcje w realne, trwałe prototypy oraz gotowe produkty. Dzięki tej technologii jesteśmy w
            stanie osiągnąć precyzyjne detale i solidną strukturę.
          </p>
          <h3>- Druk 3D SLA w żywicy</h3>
          <p>
            Posługujemy się drukiem w żywicy, co pozwala nam uzyskać obiekty o gładkich powierzchniach i szczegółach,
            które są szczególnie istotne w przypadku małych elementów lub precyzyjnych części jak modele architektoniczne.
          </p>
          <h3>- Gięcie blachy</h3>
          <p>
            Nasze usługi obejmują projektowanie pod gięcie blachy, co pozwala tworzyć złożone formy i konstrukcje
            metalowe. Sami jednak nie oferujemy gięcia blachy ale dbamy o dokładność w projektach ponieważ są kluczowe dla produkcji
            elementów, które wymagają wyjątkowej wytrzymałości i dokładności.
          </p>
          <h3>- Formy zalewowe</h3>
          <p>
            Projektujemy formy zalewowe, które są niezbędne w procesach odlewniczych. Nasze formy są starannie
            zaprojektowane, aby uzyskać idealny odlew, co jest kluczowe dla produkcji jednorodnych, wysokiej jakości
            produktów.
          </p>
          <h3>- Wycinanie CNC</h3>
          <p>
            Oferujemy projekty wycinania CNC, które umożliwiają precyzyjne wycinanie różnych materiałów, w tym metalu,
            drewna czy tworzyw sztucznych. Sami nie oferujemy wycinanania CNC ale z przyjemnością doradzimy z wyborem podwykonawcy.
          </p>
        </div>
        <img src="images\airplaneRudder.webp" alt="Wydrukowana 3d część samolotu" />
      </div>
      <div className="case">
        <div className="list">
          <h2>Tworzymy również: </h2>
          <h3>- Łączenia gwintowe</h3>
          <p>
            Nasze umiejętności obejmują precyzyjne tworzenie łączeń gwintowych, które są kluczowe w wielu konstrukcjach
            mechanicznych. Dzięki naszym zaawansowanym narzędziom komputerowym i doświadczeniu jesteśmy w stanie
            zapewnić trwałe i solidne łączenia, które spełniają najwyższe standardy jakości.
          </p>
          <h3>- Symulacje gięć</h3>
          <p>
            Wykorzystujemy technologie komputerowe do przeprowadzania symulacji gięć. Dzięki tym symulacjom możemy
            precyzyjnie zaplanować proces gięcia blachy, przewidując wszelkie możliwe problemy i optymalizując projekt,
            co prowadzi do oszczędności czasu i materiałów dla klienta.
          </p>
          <h3>- Złożenia produktów i ich animacje</h3>
          <p>
            Nasze umiejętności obejmują kompleksowe złożenia produktów oraz ich animacje. Tworzymy realistyczne
            animacje, które pozwalają naszym klientom zobaczyć ich projekty w ruchu. To nie tylko ułatwia zrozumienie
            funkcji produktów, ale także pomaga w prezentacji projektów przed ich fizyczną produkcją.
          </p>
        </div>
        <div className="images-container">
          <img src="/images/designingSubpage/2-1.webp" alt="Animacja rozkładu silnika 3D" />
          <img src="/images/designingSubpage/2-2.webp" alt="Dasza animacja rozkładu silnika 3D" />
        </div>
      </div>
      <div className="case">
        <div className="list">
          <h2>Umiemy również: </h2>
          <h3>- Odtwarzanie przedmiotu do projektu 3D pod daną technologię produkcji</h3>
          <p>
            Posiadamy zdolność dokładnego odtwarzania rzeczywistych przedmiotów w formie modeli 3D. Niezależnie od ich
            złożoności czy kształtu, nasze umiejętności pozwalają nam przenieść fizyczne obiekty do wirtualnej
            przestrzeni, co umożliwia ich dalsze przetwarzanie w różnych technologiach produkcji.
          </p>
          <h3>- Modyfikację gotowych projektów 3D pod potrzeby klienta</h3>
          <p>
            Oferujemy możliwość dostosowywania istniejących projektów 3D zgodnie z indywidualnymi wymaganiami klienta.
            Bez względu na to, czy chodzi o zmiany w rozmiarach, funkcjonalnościach czy estetyce, nasze umiejętności
            pozwalają nam na elastyczne modyfikacje gotowych projektów, dostosowując je do konkretnych potrzeb klienta.
          </p>
          <h3>- Odtwarzanie części, które są już wycofane z rynku i nie posiadają zamienników</h3>
          <p>
            Jesteśmy w stanie odtworzyć części, które są już niedostępne na rynku, a które są kluczowe dla istniejących
            urządzeń lub maszyn. Nasze zdolności umożliwiają nam precyzyjne odwzorowanie tych części w formie modeli 3D,
            co pozwala na ich ponowną produkcję, nawet gdy oryginalne komponenty są już niedostępne.
          </p>
          <h3>- Tworzenie customowych narzędzi na potrzeby klienta</h3>
          <p>
            Oferujemy również tworzenie specjalistycznych, niestandardowych narzędzi i urządzeń. Dzięki naszym
            umiejętnościom w projektowaniu narzędzi możemy opracować i stworzyć rozwiązania dostosowane do konkretnych
            procesów produkcyjnych lub zastosowań klienta, co przyczynia się do optymalizacji procesów i poprawy
            efektywności.
          </p>
        </div>
        <img id="third" src="/images/designingSubpage/3.webp" alt="Mieszalnik do paliwa wydrukowany w Nylonie" />
      </div>
    </div>
  );
};

export default DesigningSubpage;
