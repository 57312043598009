import React from "react";
import './printingSubpage.css';

const PrintingSubpage = () => {
    return (
        <div>
            <div className="image-container">
                <img src="images/banner_bambuP1P.webp" alt="background" />
            </div>
            <div className="uses-container">
                <div className="uses-title">
                    <h1>Stosujemy druk 3D w</h1>
                </div>
                <div className="icon-flex-container">
                    <div className="icon-item">
                        <img src="/images/icons/uses/automotive.webp" alt="motoryzacja" />
                        <h3>Motoryzacji</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/foundry.webp" alt="odlewnictwo" />
                        <h3>Odlewnictwie</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/steering.webp" alt="simracing" />
                        <h3>SimRacingu</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/automation.webp" alt="automatyka przemysłowa" />
                        <h3>Automatyce przemysłowej</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/education.webp" alt="edukacja" />
                        <h3>Edukacji</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/electronics.webp" alt="przemysł elektroniczny" />
                        <h3>Przemyśle elektronicznym</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/medicine.webp" alt="medycyna" />
                        <h3>Medycynie</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/military.webp" alt="sprzęt wojskowy" />
                        <h3>Sprzęcie wojskowym</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/construction.webp" alt="budownictwo" />
                        <h3>Budownictwie</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/individual.webp" alt="zlecenia indywidualne" />
                        <h3>Zleceniach indywidualnych</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/furniture.webp" alt="branża meblarska" />
                        <h3>Branży meblarskiej</h3>
                    </div>
                    <div className="icon-item">
                        <img src="/images/icons/uses/sparepart.webp" alt="zapasowe części przemysłowe" />
                        <h3>Zapasowych częściach przemysłowych</h3>
                    </div>
                </div>
            </div>
            <div className="uses-title">
                <h1>Specyfikacja naszych Drukarek 3D i ich możliwości</h1>
                <h1>Druk 3D: FDM i SLA</h1>
            </div>
            {/*
            <table className="table">
                <thead>
                    <tr>
                        <th className="table-header"></th>
                        <th className="table-header"><h3>Druk FDM</h3></th>
                        <th className="table-header"><h3>Druk SLS</h3></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="table-cell"><h3>Materiały</h3></td>
                        <td className="table-cell">PLA, Wood/Rock/Metal-filled PLA, PLA-CF/GF, PVA, PETG, PETG-CF, ABS, ASA, PC, PA, PA-CF/GF, PAHT-CF/GF, PET-CF/GF, TPU</td>
                        <td className="table-cell">Wszystkie żywice dostępne na rynku</td>
                    </tr>
                    <tr>
                        <td className="table-cell"><h3>Prędkość</h3></td>
                        <td className="table-cell">500 mm/s</td>
                        <td className="table-cell">30-50 mm/h</td>
                    </tr>
                    <tr>
                        <td className="table-cell"><h3>Dokładność</h3></td>
                        <td className="table-cell">0.012 mm</td>
                        <td className="table-cell">Rozdzielczość 4k</td>
                    </tr>
                </tbody>
            </table>
    */}
            <div className="print-case">
                <div className="print-case-text">
                    <h2>Fused Deposition Modeling (FDM)</h2>
                    <p>Fused Deposition Modeling (FDM) to technologia druku 3D,
                        która wykorzystuje topiący się materiał i warstwowe nanoszenie go,
                        aby tworzyć trójwymiarowe obiekty.
                        Oto niektóre z podstawowych statystyk naszych drukarek FDM
                    </p>
                    <ul>
                        <li><h3>Materiały</h3>FDM drukarki obsługują różnorodne materiały, w tym PLA, 
                            PLA-CF/GF, PVA, PETG, PETG-CF, ABS,
                            ASA, PC, PA, PA-CF/GF, PAHT-CF/GF, PET-CF/GF, TPU, Wood/Rock/Metal-filled PLA.
                        </li>
                        <li><h3>Prędkość</h3>Nasze drukarki FDM mogą osiągać prędkość ponad 500 mm/s,
                            co pozwala na szybkie drukowanie prototypów i zamówień.
                        </li>
                        <li><h3>Dokładność</h3> Oferujemy imponującą dokładność druku, z rozdzielczością wynoszącą 0.012 mm.
                        </li>
                    </ul>
                    <br></br>
                    <p>
                        FDM jest ceniony za swoją wszechstronność,
                        dostępność różnorodnych materiałów oraz stosunkowo niskie koszty produkcji.
                    </p>
                </div>
                <img src="/images/printingSubpage/FDM.webp" alt="Druk FDM"/>
            </div>
            <div className="print-case" id="mirrored-case">
                <div className="print-case-text" id="mirrored-text-case">
                    <h2>Stereolithography (SLA)</h2>
                    <p>Stereolithography (SLA) to metoda druku 3D,
                        która wykorzystuje światło UV do utwardzania żywic płynnych,
                        warstwa po warstwie, tworząc trwałe obiekty.
                        Oto podstawowe informacje dotyczące naszych drukarek SLA
                    </p>
                    <ul dir="rtl">
                        <li><h3>Materiały</h3>Nasze Drukarki SLA obsługują wszystkie żywice dostępne na rynku,
                            od zwyczajnych żywic do tych najbardziej wymagających takie jak medyczne, wzmocnione i pozostałe.
                        </li>
                        <li><h3>Prędkość</h3>Drukujemy z prędkością z zakresu 30-50 mm/h, żeby zachować jak największe szczegóły druku.
                        </li>
                        <li><h3>Rozdzielczość</h3> W naszych drukarkach SLA oferują bardzo wysoką rozdzielczość od 2K do nawet 8k,
                            co pozwala na uzyskiwanie obiektów o niesamowitej jakości i drobnych detalach.
                        </li>
                    </ul>
                    <br></br>
                    <p>
                        SLA jest szczególnie przydatne do tworzenia małych elementów o wysokiej jakości powierzchniowej,
                        takich jak modele prototypów, modele dentystyczne, biżuterii czy części modelarskich.
                    </p>
                </div>
                <img src="/images/printingSubpage/SLA.webp" alt=""/>
            </div>
            <div className="uses-title">
                <h1>Wycena Druku 3D</h1>
            </div>
            <div className="evaluation">
                <div className="item">
                    <img src="/images/icons/folder.webp" alt="Step 1" />
                    <h2>Wyślij nam pliki</h2>
                </div>
                <div className="item-arrow">
                    <img src="/images/icons/arrow.webp" alt="Arrow" />
                </div>
                <div className="item">
                    <img src="/images/icons/folder.webp" alt="Step 2" />
                    <h2>Opisz dodatkowe wymagania, jeżeli takie są</h2>
                </div>
                <div className="item-arrow">
                    <img src="/images/icons/arrow.webp" alt="Arrow" />
                </div>
                <div className="item">
                    <img src="/images/icons/folder.webp" alt="Step 3" />
                    <h2>Wycenę otrzymasz w ciągu 24h.</h2>
                </div>
            </div>
        </div >
    )
}

export default PrintingSubpage;
